var domain = document.location.protocol + '//' + document.location.hostname;
var cookieName    = 'pdf_pages';
var cookie;

$(function(){
  $('.main-nav-trigger').on('click', function(e) {
	$('.main-nav-trigger').toggleClass('active');
  });
  // open raw xml mode in new tab on ctrl+alt+r
  var url = window.location + '';
  if (location.href.split(location.host)[1].indexOf('/tangelo/page/') == 0) {
    $(window).keypress(function(e){
      var code = e.keyCode || e.which;
      if(code == 174 && e.ctrlKey && e.altKey) {
        var urlBase = url.split('?')[0];
        var urlParams = url.split('?')[1] ? '?' + url.split('?')[1] : '';
        var newUrl = urlBase + '/raw' + urlParams;
        window.open(newUrl,'_blank');
      }
    });
  }

  // website exit
  $.expr[':'].external = function (a) {
    return a.href.indexOf('http') == 0 && a.href.indexOf(domain) != 0;
  };
  $('a:external').each(function(){
    $(this).attr('target','_blank');
  });

  // build email links (spam prevention)
	$('a.email').each(function(){
		var pre       = $(this).data('pre');
		var after     = $(this).data('after');
		var textPre   = $(this).data('text-pre');
		var textAfter = $(this).data('text-after');
		$(this).attr('href', 'mailto:' + pre + '@' + after);
		if (textPre) $(this).text(textPre + '@' + textAfter);
	});

	/* left/right placement of tooltips */
	if ($('.content')[0]) {
  	var leftPosContent = $('.content').offset().left;
  	var widthContent   = $('.content').width();
  	$('.hint--bottom').each(function(){
  	  var leftPos = $(this).offset().left;
  	  if (widthContent / 2 < leftPos - leftPosContent) $(this).addClass('hint--leftside');
  	});
	}

	/* collapse and popup large tables */
	$('.table-zoom')
	.each(function(){
	  var minMargin   = 250;
	  var zoombutton  = $(this);
	  var table       = zoombutton.parent();
	  var tableHeight = table.height();
	  var positionTop = tableHeight > 600 ? minMargin : tableHeight / 2;

	  zoombutton.css('top',positionTop+'px');

	  if (tableHeight > 600) {
   	  var tableTop = table.offset().top;
      $(window).scroll(function(){
        var tableScrollPos = $(this).scrollTop() - tableTop;
   	    if (tableScrollPos > 0 && tableScrollPos < tableHeight - tableTop - minMargin) {
     	    positionTop = minMargin + tableScrollPos;
     	    zoombutton.css('top',positionTop+'px');
   	    }
   	  });
   	}
	})
	.click(function(){
	  $(this).parent().addClass('popup');
	})
	;

	$('.table-close').click(function(){
	  $(this).parent().removeClass('popup');
	});

	/* fix left menu on page scroll */
  $(window).scroll(function(){
    var navHeight  = $('.sidebar-left').height();
    var pageHeight = $('.content').height();
    var maxMT      = pageHeight - navHeight - 80;
    if ($(window).height() > navHeight + 20 && pageHeight > navHeight && pageHeight > 1000) {
      var scrollPos = $(this).scrollTop();
      var margintop = scrollPos > 240 ? scrollPos - 240 : 0;
      margintop     = margintop > maxMT ? maxMT : margintop;
      $('.sidebar-left').css('margin-top',margintop);
    }
  });

  $("#pdfoptions, .mobile-pdfoptions").click(function() {
         $('#pdfdiv').show();
         $('header').addClass('no-minimized');
         $("#pdfoptions, .mobile-pdfoptions").addClass('active');
         $('footer').css({'position':'fixed','width':'100%','bottom':'0'});
      });
  $("#close-pdfoptions").click(function() {
         $('#pdfdiv').hide();
         $('header').removeClass('no-minimized');
         $("#pdfoptions, .mobile-pdfoptions").removeClass('active');
         $('footer').css({'position':'relative','width':'100%','bottom':'auto'});
      });

     pdfInit();

    $('#searchbutton, .mobile-searchbutton').click(function(){
      localStorage.setItem("lasturl", window.location.href);
    });
    $('#privacy-link').click(function(){
      localStorage.setItem("lasturl", window.location.href);
    });
    
    $('#close-btn').click(function(){
		if (localStorage.getItem("lasturl").length > 10)
			window.location.assign(localStorage.getItem("lasturl"));
		else
			window.location.assign(location.protocol + '//' + location.host + location.pathname);
    });
    
    var hash = window.location.hash.split("#").pop();
    if (hash) {
       $(".accordion > h2[id='" + hash + "']").addClass("open").next(".content").slideDown();
       window.scrollBy(0,-80);
    }
});

function pdfInit() {
  cookie = $.cookie(cookieName);
  if (cookie == null || cookie == '') {
    cookie = '';
    $('.pdf #download').addClass('disabled');
  } else {
    setPageCount();
    drawPages();
  }

  $('.pdf #add:not(.disabled)').click(function(){
    var nodeInfo = $(this).attr('data-page');
    if (cookie.indexOf(nodeInfo) == -1) {
      cookie += nodeInfo + ';';
      $.cookie(cookieName,cookie,{expires:7,path:'/'});
      $('.pdf #download').removeClass('disabled');
      $(this).addClass('disabled');
      setPageCount();
      drawPages();
    }
  }).each(function(){
    var nodeInfo = $(this).attr('data-page');
    if (cookie.indexOf(nodeInfo) != -1) $(this).addClass('disabled');
  });

  $('.pdf #reset').click(function(){
    if (confirm($(this).data('confirm'))) {
      cookie = '';
      $.cookie(cookieName,cookie,{expires:7,path:'/'});
      drawPages();
      $('.pdf #download').addClass('disabled');
      $('.pdf #add[data-page]').removeClass('disabled');
      $('.pdf #selection span').text('0');
    }
  });

  $('.pdf .box').on('click','.remove',function(){
    var nodeInfo = $(this).attr('data-page');
    if (cookie.indexOf(nodeInfo) != -1) {
      cookie = cookie.replace(nodeInfo + ';','');
      $.cookie(cookieName,cookie,{expires:7,path:'/'});
      $('.pdf #add').removeClass('disabled');
      $(this).addClass('disabled');
      if (cookie.indexOf(';') == -1) $('.pdf #download').addClass('disabled');
      $(this).parents('tr').remove();
      setPageCount();
    }
  });

  $('.pdf #download').click(function(){
    if (cookie.indexOf(';') != -1) {
      var url = $(this).attr('data-href');
      var nodes = cookie.split(';');
      for(var i=0; i<nodes.length-1; i++) {
        url += splitNodeInfo(nodes[i]).id + '-';
      }
      location.href = url;
    }
  });

}

function setPageCount(){
  var pageCount = cookie.split(';').length - 1;
  $('.pdf #selection span').text(pageCount);
}

function drawPages(){
  var container = $('.box tbody');
  $('tr:has(td)',container).remove();
  var nodes = cookie.split(';');
  for(var i=0; i<nodes.length-1; i++) {
    var nodeInfo = splitNodeInfo(nodes[i]);
    container.append('<tr><td><a href="'+nodeInfo.url+'">'+nodeInfo.title+'</a></td><td width="10"><a data-page="'+nodes[i]+'" class="remove"></a></td></tr>');
  }
}

function splitNodeInfo(value) {
  var array = value.split('|');
  return {id: array[0], title: array[1], url: array[2]};
}
